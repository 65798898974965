import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';
import React, { ReactNode, useEffect } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import ModalCloseIcon from '../Icons/ModalCloseIcon';

const Modal = styled(ReactModal)`
  width: 1062px;
  max-width: 95vw;
  max-height: 95vh;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 60px 20px;
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 95vw;
    height: 80vh;
  }
`;

const IconWrapper = styled.div`
  width: 100%;
  height: 40px;
`;

const IconButton = styled.button`
  display: block;
  margin-left: auto;
  margin-bottom: 14px;
`;

type Props = {
  isOpen: boolean;
  onCloseClick?: React.MouseEventHandler<HTMLSpanElement>;
  heading?: string;
  description?: string;
  children?: ReactNode;
};

const CenterModal = (props: Props) => {
  useEffect(() => {
    const bodyEl = document.querySelector('body');
    if (!bodyEl) return;
    if (props.isOpen) {
      bodyEl.style.overflow = 'hidden';
    }
    return () => {
      bodyEl.style.overflow = '';
    };
  }, []);
  return (
    <Modal
      aria={{ labelledby: props.heading, describedby: props.description }}
      isOpen={props.isOpen}
      ariaHideApp={false}
      style={{
        content: { outline: 'none' },
        overlay: { zIndex: 999999 },
      }}
    >
      <IconWrapper>
        {props.onCloseClick && (
          <IconButton onClick={props.onCloseClick}>
            <ModalCloseIcon />
          </IconButton>
        )}
      </IconWrapper>
      {props.children}
    </Modal>
  );
};

export default CenterModal;
