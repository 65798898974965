import React from 'react';
import Result from '../../../../components/organisms/SearchByMansionName/Result';
import SellerHeader from '@/components/atoms/Header/SellerHeader';
import SellerFooter from '@/components/atoms/Footer/SellerFooter';
import { Wrapper } from '@/components/layout/common';
import { Routings } from '@/common/routings';
import { useDirectAccess } from '@/common/hooks';
import withInitializeApp from '@/utils/hoc/withInitializeApp';

const ResultPage = (data: any) => {
  const isDirectAccess = useDirectAccess(Routings.sellerTop.location);

  return isDirectAccess ? (
    <></>
  ) : (
    <Wrapper>
      <SellerHeader displayPattern="inquiry" />
      <Result assessmentData={data?.location?.state?.props.assessment} />
      <SellerFooter />
    </Wrapper>
  );
};

export default withInitializeApp(ResultPage);
