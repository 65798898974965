import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Assessment, UpdateInquiryRequest } from 'openapi/kusabi-frontgw-spec';
import { numberToJpYenFormat } from '@/utils/numberFormatter';
import { Routings } from '@/common/routings';
import AssessmentModal from '@/components/organisms/SearchByMansionName/AssessmentModal';
import { useDispatch } from 'react-redux';
import * as inquiry from '@/state/modules/inquiry';
import * as accessment from '@/state/modules/assessment';
import { Content, ContentWrapper, SpBr } from '@/components/atoms/CommonStyles';
import Terms from '@/components/atoms/Terms';
import ButtonSet from '@/components/atoms/ButtonSet';
import PageTitle from '@/components/atoms/PageTitle';
import { MansionData, MansionDataArea, SubTitle } from '../CommonStyles';
import InfoIcon from '@/components/atoms/Icons/InfoIcon';
import { Breakpoints } from '@/constants/constants';
import { Main } from '@/components/layout/common';
import theme from '@/styles/theme';

const PriceBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px 0;
  background: #ffffff;
  border: 1px solid #d1a827;
  border-radius: 10px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
    padding: 30px 0;
  }
`;

const LowerPriceBox = styled(PriceBox)`
  margin: 30px 0 15px 0;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding-bottom: 15px;
  }
`;

const PriceTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
  }
`;

const PriceTitle = styled.div`
  margin: 0 20px 0 0;
  font: ${theme.fonts.bold_22_33};
  color: ${theme.colors.blue};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin: 0 0 16px 0;
  }
`;

const PriceDescription = styled.div`
  width: 205px;
  font: ${theme.fonts.normal_12_24};
  color: ${theme.colors.text};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin: 0 0 21px 0;
    text-align: center;
  }
`;

const CenterBorder = styled.div`
  width: 1px;
  height: 50px;
  background: ${theme.colors.buttonBorder};
  margin-right: 3.7%;
  margin-left: 4.3%;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    display: none;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  width: 40%;
  white-space: nowrap;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin: 0;
    width: 100%;
  }
`;

const LowerPriceWrapper = styled.div`
  margin: 0px 20px 5px 0px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin: 0 0 15px 0;
  }
`;

const LowerPrice = styled.span`
  font: ${theme.fonts.black_40_1};
  color: ${theme.colors.gold};
  vertical-align: baseline;
`;

const LowerPriceUnit = styled.span`
  font: ${theme.fonts.black_16_1};
  color: ${theme.colors.gold};
  vertical-align: baseline;
`;

const UpperPriceWrapper = styled.div``;

const UpperPrice = styled.span`
  font: ${theme.fonts.bold_30_1};
  color: ${theme.colors.gold};
  vertical-align: baseline;
`;

const UpperPriceUnit = styled.span`
  font: ${theme.fonts.bold_16_1};
  color: ${theme.colors.gold};
  vertical-align: baseline;
`;

const UpperPriceDecoration = styled.span`
  font: ${theme.fonts.bold_16_1};
  color: ${theme.colors.blue};
  vertical-align: baseline;
`;

const InformationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 60px;
  font: ${theme.fonts.normal_12_22};
  letter-spacing: 0px;
  color: ${theme.colors.black};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    align-items: start;
    margin-bottom: 40px;
  }
`;

const Information = styled.span`
  margin-left: 11px;
`;

const DisabledButton = styled.button`
  width: 407px;
  height: 76px;
  background: ${theme.colors.buttonBorder};
  border-radius: 4px;
  font: ${theme.fonts.bold_22_33};
  color: ${theme.colors.buttonTextPrimary};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

type Props = {
  assessmentData?: Assessment;
};

const Result = ({ assessmentData }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const changeIsOpen = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  const [isAssessment, setIsAssessment] = useState(false);
  const changeIsAssessment = (isDone: boolean) => {
    setIsAssessment(isDone);
  };

  const mansionName = assessmentData?.mansionInfo?.mansionName;
  const address = `${assessmentData?.addressInfo?.prefectureName}${assessmentData?.addressInfo?.cityName}${assessmentData?.addressInfo?.townName}`;
  const mansionData = mansionName ? `${mansionName}（${address}）` : address;

  const dispatch = useDispatch();

  const handleOnClickAssessment = () => {
    const updateInquiryRequest: UpdateInquiryRequest = {
      request: '',
    };
    dispatch(inquiry.operations.updateInquiries(assessmentData?.inquiryId as number, updateInquiryRequest));
    setIsModalOpen(true);
  };
  return (
    <Main>
      <PageTitle>AI査定結果</PageTitle>

      <ContentWrapper>
        <Content>
          <SubTitle>査定マンション</SubTitle>
          <MansionDataArea>
            <MansionData>{mansionData}</MansionData>
          </MansionDataArea>
          <PriceBox>
            <PriceTitleWrapper>
              <PriceTitle>売却査定価格</PriceTitle>
              <PriceDescription>（エンドユーザーへの推定売却価格）</PriceDescription>
            </PriceTitleWrapper>
            <CenterBorder />
            <PriceWrapper>
              <LowerPriceWrapper>
                <LowerPrice>
                  {assessmentData?.priceLower && numberToJpYenFormat(assessmentData.priceLower / 10000)}
                </LowerPrice>
                <LowerPriceUnit>万円</LowerPriceUnit>
              </LowerPriceWrapper>
              <UpperPriceWrapper>
                <UpperPriceDecoration>（ 〜 </UpperPriceDecoration>
                <UpperPrice>
                  {assessmentData?.priceUpper && numberToJpYenFormat(assessmentData.priceUpper / 10000)}
                </UpperPrice>
                <UpperPriceUnit>万円</UpperPriceUnit>
                <UpperPriceDecoration>）</UpperPriceDecoration>
              </UpperPriceWrapper>
            </PriceWrapper>
          </PriceBox>
          <LowerPriceBox>
            <PriceTitleWrapper>
              <PriceTitle>買取査定価格</PriceTitle>
              <PriceDescription>（買取会社の推定買取価格）</PriceDescription>
            </PriceTitleWrapper>
            <CenterBorder />
            <PriceWrapper>
              <LowerPriceWrapper>
                <LowerPrice>
                  {assessmentData?.purchasePrice && numberToJpYenFormat(assessmentData.purchasePrice / 10000)}
                </LowerPrice>
                <LowerPriceUnit>万円</LowerPriceUnit>
              </LowerPriceWrapper>
            </PriceWrapper>
          </LowerPriceBox>
          <InformationWrapper>
            <InfoIcon color="white" />
            <Information>※ 査定価格は参考額であり、記載の金額で売却できることを保証するものではありません。</Information>
          </InformationWrapper>
          <Terms />
          {isAssessment ? (
            <ButtonSet
              mainButton={
                <DisabledButton>
                  正式査定依頼を
                  <SpBr />
                  受付けいたしました
                </DisabledButton>
              }
              subLabel="トップ画面へ"
              onSubClick={() => {
                navigate(Routings.sellerTop.location);
                dispatch(accessment.operations.clearPersonalInfoValue());
              }}
              navigateToLabel="さらに査定する"
              onNavigateTo={() => navigate(Routings.search.location)}
            />
          ) : (
            <ButtonSet
              withTerms
              mainLabel="正式な査定を依頼する"
              onMainClick={handleOnClickAssessment}
              subLabel="トップ画面へ"
              onSubClick={() => {
                navigate(Routings.sellerTop.location);
                dispatch(accessment.operations.clearPersonalInfoValue());
              }}
              navigateToLabel="さらに査定する"
              onNavigateTo={() => navigate(Routings.search.location)}
            />
          )}
        </Content>
      </ContentWrapper>
      {isModalOpen && (
        <AssessmentModal
          isModalOpen={isModalOpen}
          changeIsOpen={changeIsOpen}
          changeIsAssessment={changeIsAssessment}
        />
      )}
    </Main>
  );
};

export default Result;
