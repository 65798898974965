import TextField from '@/components/atoms/TextField';
import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';
import styled from 'styled-components';

/**
 * コンテンツ部のラッパー
 */
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 61px 0 0 0;
  padding: 0px 5%;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 0 15px;
  }
`;

/**
 * コンテンツ部のスタイル
 */
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1062px;
`;

/**
 * 各セクションの説明等
 */
export const Description = styled.h3`
  margin-bottom: 31px;
  margin-right: auto;
  text-align: left;
  color: #333333;
  font: ${theme.fonts.normal_16_32};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.normal_14_28};
  }
`;

export const Table = styled.table`
  width: 100%;
  margin-bottom: 60px;
  border-collapse: collapse;
  font: ${theme.fonts.bold_16_32};
  color: #333333;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-bottom: 60px;
  }
`;

export const TBody = styled.tbody``;

export const Tr = styled.tr`
  border-top: 1px solid #a2aab0;
  border-bottom: 1px solid #a2aab0;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    display: flex;
    flex-direction: column;

    border: none;

    &:not(:first-child) {
      margin-top: 40px;
    }
  }
`;

export const Th = styled.th`
  width: 299px;
  max-width: 299px;
  vertical-align: top;
  padding: 30px 20px;
  background: #f7f7f7;
  font: ${theme.fonts.bold_16_32};
  color: #333333;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    max-width: 100%;
    vertical-align: baseline;
    padding: 0 0 20px 0;
    background: #fff;
    font: ${theme.fonts.bold_20_32};
    border-bottom: 1px solid #a2aab0;
  }
`;

export const Td = styled.td`
  width: 763px;
  padding: 30px 0 30px 28px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    padding: 20px 0 0 0;
  }
`;

export const InnerTd = styled.div`
  width: 436px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

/**
 * テーブル内の姓名かな行のデータセルのコンテナ
 */
export const NameAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const NameArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    justify-content: space-between;
  }
`;

export const NameTextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
    width: 47%;
    margin-right: 0;
  }
`;

export const NameTextFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 190px;

  label {
    font: ${theme.fonts.bold_16_32};
    color: #333333;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const NameTextField = styled(TextField)`
  width: 143px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

export const TextArea = styled.textarea`
  width: 626px;
  height: 180px;
  padding: 10px;
  background: #ffffff;
  font: ${theme.fonts.normal_16_32};
  border: 1px solid #cccccc;
  border-radius: 4px;
  resize: none;
  line-height: 1.3;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 300px;
  }
`;

export const EmailCaution = styled.p`
  width: 100%;
  margin-top: 8px;
  font: ${theme.fonts.normal_12_20};
  color: #333333;
`;

/**
 * PCサイズのときのみ有効になる改行
 */
export const PcBr = styled.br`
  @media screen and (max-width: ${Breakpoints.sp}px) {
    display: none;
  }
`;

/**
 * SPサイズのときのみ有効になる改行
 */
export const SpBr = styled.br`
  @media screen and (min-width: ${Breakpoints.sp + 1}px) {
    display: none;
  }
`;
