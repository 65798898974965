import { PcBr, SpBr } from '@/components/atoms/CommonStyles';
import CenterModal from '@/components/atoms/CenterModal';
import { Breakpoints } from '@/constants/constants';
import React from 'react';
import styled from 'styled-components';
import theme from '@/styles/theme';

const Title = styled.div`
  margin-bottom: 60px;
  font: ${theme.fonts.bold_30_45};
  color: #4c586f;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-bottom: 40px;
    font: ${theme.fonts.bold_22_33};
  }
`;

const Message = styled.p`
  margin-bottom: 60px;
  text-align: center;
  font: ${theme.fonts.normal_16_32};
  color: #333333;
`;

const Button = styled.button`
  width: 190px;
  height: 64px;
  border: 1px solid
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font: ${theme.fonts.bold_16_33};
  color: #4c586f;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 40px;
  }
`;

type Props = {
  isModalOpen: boolean;
  changeIsOpen: (isOpen: boolean) => void;
  changeIsAssessment: (isOpen: boolean) => void;
};

const AssessmentModal = ({ isModalOpen, changeIsOpen, changeIsAssessment }: Props) => {
  const handleOnClick = () => {
    changeIsOpen(false);
    changeIsAssessment(true);
  };
  return (
    <CenterModal heading="heading" description="description" isOpen={isModalOpen}>
      <Title id="heading">
        正式査定のご依頼を
        <SpBr />
        受付けいたしました。
      </Title>
      <Message id="description">
        この度は、KUSABIの正式査定へのお申し込みありがとうございます。
        <br />
        <br />
        担当者よりメールにてご連絡いたしますので、
        <PcBr />
        今しばらくお待ちくださいませ。
      </Message>
      <Button onClick={handleOnClick}>閉じる</Button>
    </CenterModal>
  );
};

export default AssessmentModal;
