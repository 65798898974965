import React from 'react';
import styled from 'styled-components';

const Icon = styled.div`
  position: relative;
  width: 26px;
  height: 26px;
  background-color: #d1a827;
  border-radius: 50%;
  min-width: 10px;
  cursor: pointer;
  flex-shrink: 0;

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    height: 17px;
    width: 3px;
    background-color: #fff;
    content: '';
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    height: 17px;
    width: 3px;
    background-color: #fff;
    content: '';
  }
`;

type Props = {
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  className?: string;
};

const ModalCloseIcon = (props: Props) => {
  return <Icon className={props.className} onClick={props.onClick} />;
};

export default ModalCloseIcon;
