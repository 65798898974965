import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';
import React, { memo } from 'react';
import styled from 'styled-components';

const FormButton = styled.button`
  width: 407px;
  height: 76px;
  background: #4c586f;
  border-radius: 4px;

  color: #ffffff;

  opacity: 1;
  &:hover {
    opacity: 0.9;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const ButtonLabel1 = styled.span`
  font: ${theme.fonts.bold_12_23};
`;

const ButtonLabel2 = styled.span`
  font: ${theme.fonts.bold_22_33};
`;

type Props = Partial<{
  withTerms: boolean;
  label: string | JSX.Element;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}>;

const SubmitButton = (props: Props) => {
  return (
    <FormButton onClick={props.onClick} className={props.className}>
      {props.withTerms && (
        <>
          <ButtonLabel1>利用規約・個人情報の取り扱いに同意して</ButtonLabel1>
          <br />
        </>
      )}
      <ButtonLabel2>{props.label}</ButtonLabel2>
    </FormButton>
  );
};

export default memo(SubmitButton);
